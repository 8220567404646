import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { User } from '../../../models/user';
import { useUserState } from '../../../services/User';
import { collection, query, where, getDocs, updateDoc, doc, setDoc, addDoc } from 'firebase/firestore';
import { db, useAuthState } from '../../../services/firebase';

type AccountDetailsModalProps = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

const AccountDetailsModal: React.FC<AccountDetailsModalProps> = ({ showModal, setShowModal }) => {
  const { user, setUser } = useUserState();
  const { user: authUser } = useAuthState();

  const [saving, setSaving] = React.useState(false);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Child's First Name is required"),
    lastName: Yup.string().required("Child's Last Name is required"),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setSaving(true);
      console.log(values);
      await createUserInDatabase(values);
      setShowModal(false);
    },
  });

  async function createUserInDatabase(data: any) {
    console.log(authUser);
    try {
      const sessionId = localStorage.getItem('subscriptionId');

      const userRef = collection(db, 'user'); // Reference to the 'user' collection
      await addDoc(userRef, {
        email: authUser.email,
        firstName: data.firstName,
        lastName: data.lastName,
        uid: authUser.uid,
        checkoutSessions: [ sessionId ]
        //birthDate: data.birthDate,
      });
      console.log('User created successfully');
      setSaving(false)
    } catch (error) {
      console.error('Error creating user:', error);
    }
  }

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      dialogClassName="global-modal reset-modal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Account Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group controlId="formFirstName">
            <Form.Label>Child's First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.firstName && formik.touched.firstName}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.firstName}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formLastName">
            <Form.Label>Child's Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.lastName && formik.touched.lastName}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.lastName}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.password && formik.touched.password}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formConfirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.confirmPassword && formik.touched.confirmPassword}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.confirmPassword}</Form.Control.Feedback>
          </Form.Group>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={saving}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AccountDetailsModal;