// src/pages/examCreation/stepTwo/ImageUpload.tsx
import React from 'react';

type ImageUploadProps = {
  customClass: string;
  selectedImageUploadIndex: number;
  setSelectedImageUploadIndex: (index: number) => void;
  setSelectedImageUpload: (file: File) => void;
  setLatestImageClick: (index: number) => void;
  latestImageNumber: number;
  imageUrl?: string;
  name: string;
};

const ImageUpload: React.FC<ImageUploadProps> = ({
  customClass,
  selectedImageUploadIndex,
  setSelectedImageUploadIndex,
  setSelectedImageUpload,
  latestImageNumber,
  setLatestImageClick,
  imageUrl,
  name,
}) => {
  return (
    <div
      className={customClass + (selectedImageUploadIndex === 2 ? ' selected' : '')}
      onClick={() => setSelectedImageUploadIndex(latestImageNumber)}
    >
      {imageUrl ? (
        <img className="uploaded-file" src={imageUrl} alt="uploaded file" />
      ) : (
        <img className="upload-img" src="/icons/upload.svg" />
      )}
      <input
        className="upload-input"
        name={name}
        type="file"
        onChange={(e) => {
          setLatestImageClick(latestImageNumber);
          setSelectedImageUpload(e.target.files![0]);
        }}
      />
    </div>
  );
};

export default ImageUpload;