import { useRef, useState } from 'react';
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, UserCredential } from 'firebase/auth';
import Button from 'react-bootstrap/Button';
import './login.scss';
import * as Yup from 'yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAnalytics, setUserProperties } from 'firebase/analytics';
//import { SocialLogin } from '../../components/signup/SocialLogin';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { Auth } from '../../layouts/auth/auth';
import { useGlobalState } from '../../services/globals';
import { collection, doc, getDoc, query, where } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { Utils } from '../../util/string-utils';
import { ToastMessage } from '../../components/toast/ToastMessage';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { QueryUtils } from '../../util/query-utils';

const initialValues = {
  email: '',
  password: '',
};

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Email is required'),
  //username: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Username is required'),
  password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
});

//http://localhost:3000/auto-create-user/cs_live_a1qhMArOtpCs0q5wMTM4g4ggVsbx44EMgNVjlqnW6adopTz9GQK6i1zp7K

export const Login = () => {
  //const { height, width } = useWindowDimensions();
  //const forgotPasswordRef: any = useRef(null);
  const {subscription} = useParams();
  const navigate = useNavigate();
  const toastRef: any = useRef(null);
  const [loading, setLoading] = useState(false);
  const global = useGlobalState();
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const auth = getAuth();

      if (subscription?.includes('subscriptionIntent')) {
        sessionStorage.setItem('subscriptionIntent', subscription?.split(':')[1] || '');
      }

      try {
        //const generatedEmail = values.username + '_' + values.email;
        console.log('looging in with: ', values)
        const uc: UserCredential = await signInWithEmailAndPassword(auth, values.email, values.password);

        const analytics = getAnalytics();
        setUserProperties(analytics, { registered: 'yes' });

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (e: any) {
         toastRef.current.toggleShow(Utils.formatErrorMsg(e.message));
         setLoading(false);
      }
    },
  });

  async function sendEmailResetPassword() {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent successfully');
      toastRef.current.toggleShow('Email sent successfully!');
      setShowModal(false);
    } catch (e: any) {
      console.error('Error sending password reset email:', e);
      toastRef.current.toggleShow('Invalid username or email');
    }
  }

 /* async function sendEmailResetPassword() {
     if (sendLoading) return;
    setSendLoading(true)

    var firstLetter = username[0].toUpperCase();
    var lastLetter = username[username.length - 1].toUpperCase();
    var middleLetters = username.substring(1, username.length - 1);

    const userFinal = firstLetter + middleLetters + lastLetter;
    //can you find user in firebase firestore by username and email
    const querySub = query(collection(db, 'user'), where('email', '==', email), where('login', '==', userFinal));
    const resp: any = await QueryUtils.executeQuery(querySub);

    if (resp && resp[0]) {
      const user = resp[0];
      await axios.post('https://us-central1-mock-exam-centre-prod.cloudfunctions.net/sendPasswordResetEmail', {userId: user.id, isParental: false});
      toastRef.current.toggleShow('Email sent successfully!');
      setShowModal(false);
    } else {
      toastRef.current.toggleShow('Invalid username or email');
    }
    setSendLoading(false)
  } */

  //TODO convert to react bootstrap form
  return (
    <Auth>
      <section id="login" className="bg-white">
        <div className="login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden px-10 p-lg-7 mx-auto mw-450px w-100">
          <div className="d-flex flex-column-fluid container-login">
            <form className="form w-100 login-form" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">

              {/* begin::Title */}
              <div className="pb-lg-15 mt-41">
                <h3 className="fw-bolder text-dark display-6">Welcome</h3>
                <div className="text-muted fw-bold fs-3">
                  New Here?
                  <Link to="/signup" className="text-primary fw-bold ml-2" id="kt_login_signin_form_singup_button">
                    Create Account
                  </Link>
                </div>

              </div>
              {/* begin::Title */}

              {formik.status ? (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
              ) : (
                <></>
               /*  <div className="mb-lg-15 alert alert-info">
                  <div className="alert-text ">
                    Use credentials <strong>admin@demo.com</strong> and <strong>demo</strong> to sign in.
                  </div>
                </div> */
              )}

              {/* begin::Form group */}
              <div className="v-row mb-10 fv-plugins-icon-container mt-12">

                <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                <input id='user-name-input'
                  placeholder="Email"
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type="email"
                  name="email"
                  autoComplete="off"
                />

              <h4 className='info-login'>The username is composed by your child's first name, followed by the last name's first letter, ie: JonhS</h4>
                {formik.touched.email && formik.errors.email && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className="mb-10 fv-plugins-icon-container">
                <div className="d-flex justify-content-between mt-n5">
                  <label className="form-label fs-6 fw-bolder text-dark pt-5">Password</label>

                  <Link
                    to=""
                    onClick={() => setShowModal(true)}
                    className="text-primary fs-6 fw-bolder text-hover-primary pt-5"
                    id="kt_login_signin_form_password_reset_button"
                  >
                    Forgot Password ?
                  </Link>
                </div>
                <input id='password'
                  type="password"
                  autoComplete="off"
                  placeholder="Password"
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.password}</div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Action */}
              <div className="pb-lg-0 pb-5">
                <button
                  type="submit"
                  id="kt_login_signin_form_submit_button"
                  className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className="indicator-label">Sign In</span>}
                  {loading && (
                    <span className="indicator-progress" style={{ display: 'block' }}>
                      Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
              {/* end::Action */}
            </form>
          </div>
        </div>

        <Modal show={showModal} onHide={() => setShowModal(false)} centered dialogClassName="global-modal reset-modal">
          <Modal.Header closeButton>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span className='first-line'>Email</span>
              <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} className="form-control form-control-lg form-control-solid"></input>
            </div>

            {/* <div>
              <span className='first-line'>Username (First name of child + first letter of last name)</span>
              <input type='email' value={username} onChange={(e) => setUsername(e.target.value)} className="form-control form-control-lg form-control-solid"></input>
            </div>*/}

            <div className='custom-success-footer'>
            <Button onClick={() => setShowModal(false)} variant="light" className="back-btn btn-short">
                <span>Cancel</span>
              </Button>

              <button className="btn btn-primary fs-6 px-8 py-4 me-3 second-button"  onClick={sendEmailResetPassword}>
            {!sendLoading && <span className="indicator-label">Send</span>}
              {sendLoading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Send <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
            </button>
          </div>
          </Modal.Body>

        </Modal>
      </section>
      <ToastMessage ref={toastRef} />
    </Auth>
  );
};
