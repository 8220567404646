// src/pages/examCreation/stepTwo/ImageUpload.tsx
import React from 'react';
import { Question } from '../../../../models/question';
import { Button, Form } from 'react-bootstrap';
import ImageUpload from '../imageUpload/ImageUpload';

type ImageUploadProps = {
  selectedQuestion: Question;
  selectedImageUploadIndex: number;
  setSelectedImageUploadIndex: (index: number) => void;
  setSelectedImageUpload: (file: File) => void;
  setLatestImageClick: (index: number) => void;
  showHeaderImage: boolean;
  updateHeaderImage: any
};

const ImageUploadContainer: React.FC<ImageUploadProps> = ({
  selectedQuestion,
  selectedImageUploadIndex,
  setSelectedImageUploadIndex,
  setSelectedImageUpload,
  setLatestImageClick,
  showHeaderImage,
  updateHeaderImage,
}) => {
  return (
    <>
    <span>Question Image</span>

    <ImageUpload
      customClass={selectedQuestion?.questionImageUrl ? 'img-container' : 'upload-box'}
      selectedImageUploadIndex={selectedImageUploadIndex}
      setSelectedImageUploadIndex={setSelectedImageUploadIndex}
      setSelectedImageUpload={setSelectedImageUpload}
      latestImageNumber={0}
      setLatestImageClick={setLatestImageClick}
      imageUrl={selectedQuestion?.questionImageUrl}
      name="questionImage"
    />

    <span>Resolution Image</span>
    <ImageUpload
      customClass={selectedQuestion?.resolutionImageUrl ? 'img-container' : 'upload-box'}
      selectedImageUploadIndex={selectedImageUploadIndex}
      setSelectedImageUploadIndex={setSelectedImageUploadIndex}
      setSelectedImageUpload={setSelectedImageUpload}
      latestImageNumber={1}
      setLatestImageClick={setLatestImageClick}
      imageUrl={selectedQuestion?.resolutionImageUrl}
      name="resImage"
    />

    <div className="toggle-container">
      {showHeaderImage && (
        <ImageUpload
          customClass={selectedQuestion?.headerImageUrl ? 'img-container' : 'upload-box'}
          selectedImageUploadIndex={selectedImageUploadIndex}
          setSelectedImageUploadIndex={setSelectedImageUploadIndex}
          setSelectedImageUpload={setSelectedImageUpload}
          latestImageNumber={2}
          setLatestImageClick={setLatestImageClick}
          imageUrl={selectedQuestion?.headerImageUrl}
          name="headerImage"
        ></ImageUpload>
      )}

      <Form.Check
        type="switch"
        id="custom-switch"
        label="Header image"
        checked={showHeaderImage}
        onChange={() => updateHeaderImage()}
      />
    </div>
    </>
  );
};

export default ImageUploadContainer;