import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { db } from '../../services/firebase';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { Modal, Button, Form } from 'react-bootstrap';
import { useUserState } from '../../services/User';
import { collection, query, where, getDocs } from 'firebase/firestore';


export const AutoCreateUser = () => {
  const { user, setUser } = useUserState();
  const { session_id } = useParams<{ session_id: string }>();
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState('');
  const [showPwResetText, setShowPwResetText] = useState(false);
  const [email, setEmail] = useState('');

  console.log(session_id);

  useEffect(() => {
    getUserInfo();
  }, [session_id]);

  async function getUserInfo() {
    console.log('getUserInfo', session_id);
    if (!session_id) {
      console.log('No session_id provided');
      return;
    }
    try {
      const headers = {
        Authorization: 'Bearer ' + process.env.REACT_APP_PUBLIC_STRIPE_API_KEY,
      };

      const response = await axios.post(
        'https://us-central1-mock-exam-centre-prod.cloudfunctions.net/getSessionById',
        {
          sessionId: session_id,
        },
        { headers }
      );

      console.log('Response from getSessionById:', response.data);
      localStorage.setItem('subscriptionId',  response.data.subscription);
      const userData = response.data.customer_details;
      setEmail(userData.email);
      await createAuthUser(userData);
    } catch (e: any) {
      console.log('Error calling getSessionById:', e);
    }
  }

  function generateRandomPassword(length: number) {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  }

  async function createAuthUser(data: any) {
    const auth = getAuth();
    try {
      // Check if the user already exists
      const signInMethods = await fetchSignInMethodsForEmail(auth, data.email);
      if (signInMethods.length > 0) {
        console.log('User already exists with email:', data);
        setUser({ email: data.email});
        setShowModal(true);
        return;
      }

      // Create a new user if they do not exist
      const userCredential = await createUserWithEmailAndPassword(auth, data.email, generateRandomPassword(8));
      console.log('User created successfully:', userCredential);
      setUser({ email: userCredential.user.email });
      //createUserInDatabase(userCredential.user?.uid, data.email, randomPassword, data.firstname, data.lastname, parentalPassword, data.birthDate);
    } catch (e: any) {
      console.error(e);
    }
  }

  async function handleLogin() {
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('User logged in successfully:', userCredential);
      setShowModal(false);
      setUser({ email: userCredential.user.email, uid: userCredential.user.uid });
    } catch (e: any) {
      console.error('Error logging in:', e);
    }
  }

  async function handlePasswordReset() {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent successfully');
      setShowPwResetText(true);
    } catch (e: any) {
      console.error('Error sending password reset email:', e);
    }
  }

  return (
    <div>
      loading
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body className="flex flex-col">
          <Form>
            <Form.Group controlId="formPassword">
              <Form.Label>Please sign in with your password</Form.Label>
              <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>
          </Form>

          {!showPwResetText && (
            <Link
              to="#"
              onClick={handlePasswordReset}
              className="text-primary fs-6 fw-bolder text-hover-primary pt-5"
              id="kt_login_signin_form_password_reset_button"
            >
              I don't know my password
            </Link>
          )}

          {showPwResetText && (
            <p className="text-muted fs-6 fw-bolder text-hover-primary pt-5">
              A password reset email has been sent to your email address, please also check your spam folder!
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleLogin}>
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};