import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthContextProvider } from './services/firebase';
import ProtectedRoutes from './services/protected-routes';
import PublicRoutes from './services/public-routes';
import './App.scss';
import { UserContextProvider } from './services/User';
import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { Login } from './pages/login/Login';
import Sidebar from './layouts/sidebar/Sidebar';
import CustomNavBar from './layouts/navbar/Navbar';
import { SignUp } from './pages/signup/SignUp';
import { GlobalContextProvider } from './services/globals';
import ExamsList from './pages/examsList/ExamsList';
import { StaticContextProvider } from './services/StaticContext';
import { LoaderProvider } from './services/LoaderProvider';
import NavbarLanding from './layouts/navbar-landing/NavbarLanding';
import FreeTest from './pages/freeTest/FreeTest';
import { ResetPassword } from './pages/reset-password/ResetPassword';
import { ToastProvider } from './services/ToastContext';
import { NotFound } from './pages/not-found/NotFound';
import AdminDashboard from './pages/admin-dashboard/AdminDashboard';
import Profile from './pages/profile/Profile';
import Home from './pages/home/Home';
import ExamsManagement from './pages/examsManagement/ExamsManagement';
import ExamPerform from './pages/examPerform/ExamPerform';
import ExamCreation from './pages/examCreation/ExamCreation';
import ExamCreationStep2 from './pages/examCreation/stepTwo/ExamCreationStep2';
import ExamAnswers from './pages/exam-answers/ExamAnswers';
import Help from './pages/help/Help';
import { AutoCreateUser } from './pages/auto-create-user/AutoCreateUser';

const Wrapper = ({ children }: any) => {

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.style.scrollBehavior = 'auto';
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <GlobalContextProvider>
      <LoaderProvider>
      <AuthContextProvider>
      <ToastProvider>
      <StaticContextProvider>
        <UserContextProvider>
          <Router>
            <Wrapper>
              <div className="main-content-container">
                <div className="main-content-wrapper">
                  <Sidebar />
                  <div className={'main-content'}>
                    <CustomNavBar />
                    <NavbarLanding />
                    <div className="content-data">
                      <Routes>
                        <Route path="/" element={<ProtectedRoutes />}>

                        <Route path="dashboard" element={
                            <Suspense fallback={<div></div>}>
                              <Home />
                            </Suspense>
                          } />

                          <Route path="admin-dashboard" element={
                            <Suspense fallback={<div></div>}>
                              <AdminDashboard />
                            </Suspense>
                          } />
                          <Route path="profile" element={
                            <Suspense fallback={<div></div>}>
                              <Profile />
                            </Suspense>
                          } />
                          <Route path="help" element={
                            <Suspense fallback={<div></div>}>
                              <Help />
                            </Suspense>
                          } />
                          <Route path="exams-list" element={
                            <Suspense fallback={<div></div>}>
                              <ExamsList />
                            </Suspense>
                          } />
                          <Route path="exams-list/:payment" element={
                            <Suspense fallback={<div></div>}>
                              <ExamsList />
                            </Suspense>
                          } />
                          <Route path="exams-management" element={
                            <Suspense fallback={<div></div>}>
                              <ExamsManagement />
                            </Suspense>
                          } />
                          <Route path="exam-perform/:examId/:resultId/:resultCollection" element={
                            <Suspense fallback={<div></div>}>
                              <ExamPerform />
                            </Suspense>
                          } />
                          <Route path="exam-perform/:examId/:resultId" element={
                            <Suspense fallback={<div></div>}>
                              <ExamPerform />
                            </Suspense>
                          } />

                          <Route path="exam-creation" element={
                            <Suspense fallback={<div></div>}>
                              <ExamCreation />
                            </Suspense>
                          } />

                          <Route path="exam-creation/:examId" element={
                            <Suspense fallback={<div></div>}>
                              <ExamCreation />
                            </Suspense>
                          } />

                          <Route path="exam-creation-subject/:examId" element={
                            <Suspense fallback={<div></div>}>
                              <ExamCreationStep2 />
                            </Suspense>
                          } />

                          <Route path="exam-perform/:examId" element={
                            <Suspense fallback={<div></div>}>
                              <ExamAnswers />
                            </Suspense>
                          } />

                          <Route path="/" element={<Navigate to="exams-list" />} />

                        </Route>


                        <Route path="/" element={<PublicRoutes />}>
                          <Route path="signup" element={<SignUp />} />
                          <Route path="signup/:subscription" element={<SignUp />} />
                          <Route path="exam-perform/:examId/:resultId/:resultCollection/public" element={
                            <Suspense fallback={<div></div>}>
                              <ExamPerform />
                            </Suspense>
                          } />
                          <Route path="login" element={<Login />} />
                          <Route path="auto-create-user/:session_id" element={<AutoCreateUser />} />
                          <Route path="login/:subscription" element={<Login />} />
                      {/*     <Route path="free-test" element={<FreeTest />} /> */}
                          <Route path="free-test" element={
                            <Suspense fallback={<div></div>}>
                              <FreeTest />
                            </Suspense>
                          } />
                        </Route>
                        <Route path="reset-password/:token" element={<ResetPassword />} />
                        <Route path="parental/reset-password/:token" element={<ResetPassword />} />
                       {/*  <Route path="landing" element={<Landing />} />
                        <Route path="landing/:section" element={<Landing />} /> */}

                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </div>
                  </div>
                </div>
              </div>
            </Wrapper>
          </Router>
        </UserContextProvider>
        </StaticContextProvider>
        </ToastProvider>
      </AuthContextProvider>
      </LoaderProvider>
    </GlobalContextProvider>
  );
}

export default App;
