import { createContext, useContext, useEffect, useState } from 'react';
import { db, useAuthState } from './firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { User } from '../models/user';

export const UserContext = createContext({});

export const UserContextProvider = (props: any) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { user: authUser, loading: isLoading } = useAuthState();

  useEffect(() => {
    if (!authUser) {
      setUser(null);
      if (!isLoading) {
        setLoading(false);
      }
      return;
    }

    const usersRef = collection(db, 'user');
    const q = query(usersRef, where('email', '==', authUser.email));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.empty) {
        setUser(null);
        setLoading(false);
        return;
      }

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        console.log('UserContextProvider', data);
        if (!data || !data.email) {
          setLoading(false);
          return;
        }
        const user = { ...(data as User), id: doc.id };

        console.log('UserContextProvider', user);

        setUser(user);
        setLoading(false);
      });
    });

    return () => unsubscribe();
  }, [authUser, isLoading]);

  return <UserContext.Provider value={{ user, loading, setUser }} {...props} />;
};

export const useUserState = () => {
  const ctx: any = useContext(UserContext);
  return { ...ctx.user, loading: ctx.loading, setUser: ctx.setUser };
};